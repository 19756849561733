'use client'

import { Box, useMediaQuery } from '@chakra-ui/react'
import { Hero } from 'components/home/Hero'
import React from 'react'
import NavBar from 'components/navbar'
import { colors } from 'styles/foundations'
import {
  UtilityCards,
  NotificationFeature,
  FeaturesStripe,
  PaymentSetups,
  MobileFeatured,
  PlansSection,
  AutofundFeature,
  CustomersComments,
  DownloadApp
} from 'components'
import Footer from 'components/footer'

import { ParallaxProvider } from 'react-scroll-parallax'
import FeatureSection from 'components/home/FeatureSection'
import ScrollToTopButton from 'components/scroll-to-top'
import Head from 'next/head'

export default function Page() {
  const [isDesktop] = useMediaQuery('(max-width: 1020px)')

  return (
    <>
      <Head>
        <title>Bill Plan App | Simplify Bill Management, Automate Payments | Lint</title>
        <meta
          name="description"
          content="Streamline your bill management with our powerful bill plan app. Save up for your bills, automate payments, get insights into your spending, and enjoy hassle-free financial organization. Try it now!"
        />
        <meta
          name="keywords"
          content="bill plan app, automate payments, bill management, financial organization, streamline bills, insights, budgeting, personal finance"
        />

        {/* Open Graph (OG) Tags for Social Media Sharing  */}
        <meta
          property="og:title"
          content="Bill Plan App | Simplify Bill Management, Automate Payments | Lint"
        />
        <meta
          property="og:description"
          content="Streamline your bill management with our powerful bill plan app. Save up for your bills, automate payments, get insights into your spending, and enjoy hassle-free financial organization. Try it now!"
        />
        <meta property="og:image" content="/images/hero-image-compressed.png" />
        <meta property="og:url" content="https://www.lint.finance" />
        <link rel="icon" href="/icons/fav.png" />
        <meta name="apple-itunes-app" content="app-id=6469200392" />
      </Head>
      <Box bg={colors.brand.white} w="full">
        <ParallaxProvider>
          <ScrollToTopButton />
          <NavBar />
          <Hero />
          <FeaturesStripe />
          <PlansSection />
          <DownloadApp />
          <PaymentSetups />
          {isDesktop ? <MobileFeatured /> : <FeatureSection />}
          <Box>
            <NotificationFeature />
          </Box>
          {/* <ScrollComponent /> */}
          <CustomersComments />
          <AutofundFeature />
          <UtilityCards />
          <Footer />
        </ParallaxProvider>
      </Box>
    </>
  )
}

// export default Page
